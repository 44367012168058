<template>
  <vx-card>
    <div>
      <div class="vx-col mb-6 w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Shipment Doc</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="table.data.shipment_number"
                :disabled="true"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Customer Data</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="table.data.customer_code"
                :disabled="true"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>SO Data</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="table.data.sales_order_code"
                :disabled="true"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>DO Data</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="table.data.delivery_order_code"
                :disabled="true"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>POD Date</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="table.data.proof_of_delivery_date"
                type="date"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>POD Status</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <multiselect
                class="selectExample"
                v-model="selectedStatus"
                :options="optionalStatus"
                :disabled="editOrShow"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="100"
                :limit="3"
                placeholder="Select one"
                :searchable="true"
                :custom-label="customLabelStatus"
                @select="handleSelectStatus()"
              />
            </div>
          </div>
        </div>
        <div
          class="vx-row mb-6"
          v-if="selectedStatus.ID == 4 || selectedStatus.ID == 5"
        >
          <div class="vx-col sm:w-1/5 w-full">
            <span>Reason</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <multiselect
                class="selectExample"
                v-model="selectedReason"
                :options="optionReason"
                :disabled="editOrShow"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="100"
                :limit="3"
                placeholder="Select one"
                :searchable="true"
                track-by="id"
                label="reason"
                name="reason"
                @select="handleSelectReason()"
              />
              <span class="text-danger text-sm" v-show="errors.has('reason')"
                >Reason is required</span
              >
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Document</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-checkbox
                v-model="table.data.is_document"
                color="success"
                :disabled="editOrShow"
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <div class="vx-row flex justify-between mt-5 m-10">
        <div class="flex flex-row justify-between gap-2">
          <vs-button class="mb-2" v-on:click="SubmitForm()" v-if="!editOrShow">
            Save
          </vs-button>
          <vs-button class="mb-2" v-on:click="ProcessData()" v-if="!editOrShow">
            Process
          </vs-button>
        </div>
        <div>
          <vs-button class="mb-2" v-on:click="handleClose()">Close</vs-button>
        </div>
      </div>
      <vs-table
        stripe
        border
        description
        :sst="true"
        :data="itemLine"
        :max-items="itemLine.length"
      >
        <template slot="thead">
          <vs-th sort-key="item_name">SKU Name</vs-th>
          <vs-th sort-key="item_name">SKU Code</vs-th>
          <vs-th sort-key="batch">BATCH</vs-th>
          <vs-th sort-key="zone_name">Quantity</vs-th>
          <vs-th sort-key="name">Accepted Quantity</vs-th>
          <vs-th sort-key="name">Rejected Quantity</vs-th>
          <vs-th sort-key="name">Note</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.item_name">
              {{ tr.item_name }}
            </vs-td>
            <vs-td :data="tr.item_code">
              {{ tr.item_code }}
            </vs-td>
            <vs-td :data="tr.batch">
              {{ tr.batch }}
            </vs-td>
            <vs-td :data="tr.quantity">
              {{ tr.quantity }}-{{ tr.item_unit }}
            </vs-td>
            <vs-td>
              <div class="vx-col w-full flex flex-col justify-end">
                <vs-input
                  class="w-full"
                  v-model="tr.adjusted_quantity"
                  disabled
                  type="number"
                />
                <multiselect
                  class="selectExample"
                  v-model="tr.accepted_item"
                  :options="tr.item_units"
                  :multiple="false"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="100"
                  :limit="3"
                  placeholder="Select one"
                  :searchable="true"
                  track-by="id"
                  label="unit_name"
                  disabled
                  @select="selectedUnit(indextr)"
                />
              </div>
            </vs-td>
            <vs-td>
              <div
                class="w-full flex justify-end gap-2"
                v-for="(tk, index) in itemLine[indextr].rejected_reason"
                :key="index"
                :data="tk"
              >
                <div class="w-3/4 flex flex-col justify-end">
                  <div class="w-full flex flex-row justify-end gap-10">
                    <span>HU:</span>
                    <multiselect
                      class="selectExample"
                      v-model="tr.rejected_reason[index]"
                      :options="tr.item_units"
                      :multiple="false"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="100"
                      :limit="3"
                      placeholder="Select one"
                      :searchable="true"
                      track-by="id"
                      label="unit_name"
                      :disabled="disableRejectAndAccept"
                      @select="rejectedCheck(indextr, index)"
                    />
                  </div>
                  <div class="w-full flex flex-row justify-end gap-10">
                    <span class="w-1/8">Qty:</span>
                    <vs-input
                      class="w-full"
                      v-model="tk.quantity"
                      :disabled="disableRejectAndAccept"
                      type="number"
                      @input="rejectedCheck(indextr, index)"
                    />
                  </div>
                  <div class="w-full flex flex-row justify-end gap-2">
                    <span>Reason:</span>

                    <multiselect
                      class="selectExample"
                      v-model="tr.rejected_reason[index].reason"
                      :options="optionReason"
                      :multiple="false"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="100"
                      :limit="3"
                      placeholder="Select one"
                      :searchable="true"
                      track-by="id"
                      label="reason"
                      :disabled="disableRejectAndAccept"
                    />
                  </div>
                  <br v-if="itemLine[indextr].rejected_reason.length > 1" />
                </div>
                <div class="vx-col w-1/4 flex flex-col justify-center">
                  <div class="vx-input-group flex">
                    <vs-button
                      v-if="index != 0 && !disableRejectAndAccept"
                      @click.stop="removeRowReason(indextr, index)"
                      size="small"
                      color="danger"
                      icon-pack="feather"
                      icon="icon-trash"
                      style="margin-right: 5px"
                      title="Remove Row"
                    />

                    <vs-button
                      v-if="!disableRejectAndAccept"
                      @click.stop="addRowReason(indextr)"
                      size="small"
                      color="success"
                      icon-pack="feather"
                      icon="icon-plus"
                      title="Add Row"
                    />
                  </div>
                </div>
              </div>
            </vs-td>
            <vs-td>
              <div>
                <vs-input
                  class="w-full"
                  v-model="tr.notes"
                  :disabled="disabledReason"
                  type="text"
                />
              </div>
            </vs-td>
          </vs-tr>
          <tr
            v-for="index in addTr"
            :key="index"
            style="background: transparent"
          >
            <td></td>
          </tr>
        </template>
      </vs-table>
      <!-- <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        /> -->
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
export default {
  components: {},

  async mounted() {
    this.handleCheckEditOrShow();
    let params = {
      length: 999,
      order: "id",
      sort: "asc",
      reason_groups_name: ["POD Failed", "POD Partial"],
    };
    await this.getData();
    await this.getReasons(params);
  },

  data() {
    return {
      isAllowPartial: true,
      editOrShow: false,
      table: this.tableDefaultState(),
      warehouseID: null,

      dateNow: moment().format("YYYY-MM-DD"),
      selectedStatus: { name: "", ID: 0 },
      optionalStatus: [
        { name: "Complete", ID: 1 },
        { name: "Partial Complete ", ID: 2 },
        // { name: "Partial Redeliver ", ID: 3 },
        { name: "Failed Cancel", ID: 4 },
        { name: "Failed Redeliver", ID: 5 },
      ],
      disableRejectAndAccept: true,
      disabledReason: true,
      addTr: 3,
      closeTr: 3,
      optionReason: [],
      optionReasonMain: [],
      selectedReason: null,
      isDelivered: false,
      itemLine: [],
      huSelectTransit: {
        amount_uom: 0,
        id: 0,
        level: 0,
        item_id: 0,
        unit_id: 0,
        unit_name: "",
      },

      IDitem: {
        id: 0,
        status: 2,
      },
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },

    async getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/proof-of-delivery/edit", {
          params: {
            id: this.$route.query.id,
          },
        })
        .then((resp) => {
          // console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data;
            this.isAllowPartial = this.table.data.is_allow_partial;
            if (this.isAllowPartial == false) {
              //set optionalStatus disable Partial Complete
              this.optionalStatus = this.optionalStatus.filter(
                (status) => status.ID != 2
              );
            }

            this.table.data.proof_of_delivery_date =
              this.table.data.proof_of_delivery_date != ""
                ? moment(this.table.data.proof_of_delivery_date).format(
                    "YYYY-MM-DD"
                  )
                : moment().format("YYYY-MM-DD");
            this.isDelivered = resp.data.is_delivered;

            this.optionalStatus.forEach((element) => {
              if (element.ID == this.table.data.status_delivery) {
                this.selectedStatus = element;
              }
            });
            //set is_document default value true
            if (this.table.data.is_document) {
              this.table.data.is_document = resp.data.is_document;
            } else {
              this.table.data.is_document = true;
            }

            //

            for (let index = 0; index < resp.data.items_pod.length; index++) {
              // console.log("JJJJJJ", this.table.data.items_pod[index]);

              let dataAccept = null;
              if (this.table.data.status_delivery == 2) {
                dataAccept = this.table.data.items_pod[index].item_units.filter(
                  (unit) => unit.level == 1
                )[0];

                //change quantity to table.data.items_pod[index].adjusted_quantity
                dataAccept.quantity =
                  this.table.data.items_pod[index].adjusted_quantity;
              } else {
                dataAccept = this.table.data.items_pod[index].item_units.filter(
                  (unit) =>
                    unit.id == this.table.data.items_pod[index].item_unit_id
                )[0];
              }

              let responseRejectedReason = [];
              if (this.table.data.items_pod[index].rejected_reason != null) {
                responseRejectedReason =
                  this.table.data.items_pod[index].rejected_reason;
              } else {
                if (
                  this.table.data.status_delivery == 4 ||
                  this.table.data.status_delivery == 5
                ) {
                  responseRejectedReason = [
                    {
                      amount_uom: 0,
                      level: 0,
                      unit_name: "",
                      unit_id: 0,
                      id: 0,
                      quantity: this.table.data.items_pod[index].qty_after_conv,
                      is_new: false,
                      reason: this.selectedReason,
                    },
                  ];
                } else {
                  responseRejectedReason = [
                    {
                      amount_uom: 0,
                      level: 0,
                      unit_name: "",
                      unit_id: 0,
                      id: 0,
                      quantity: null,
                      is_new: false,
                      reason: null,
                    },
                  ];
                }
              }
              //append data line to itemLine

              this.itemLine.push({
                id: resp.data.items_pod[index].id,
                delivery_order_line_id:
                  resp.data.items_pod[index].delivery_order_line_id,
                item_code: resp.data.items_pod[index].item_code,
                item_name: resp.data.items_pod[index].item_name,
                item_unit: resp.data.items_pod[index].item_unit,
                quantity: resp.data.items_pod[index].quantity,
                reason_id: resp.data.items_pod[index].reason_id,
                notes: resp.data.items_pod[index].notes,
                batch: resp.data.items_pod[index].batch,
                combo_id: resp.data.items_pod[index].combo_id,
                combo_code: resp.data.items_pod[index].combo_code,
                combo_quantity: resp.data.items_pod[index].combo_quantity,
                combo_quantity_general:
                  resp.data.items_pod[index].combo_quantity_general,
                item_units: resp.data.items_pod[index].item_units,
                qty_after_conv:
                  resp.data.items_pod[index].quantity *
                  resp.data.items_pod[index].amount_uom,
                //if adjust qty is null then set to item after conv
                adjusted_quantity: resp.data.items_pod[index].adjusted_quantity
                  ? resp.data.items_pod[index].adjusted_quantity
                  : 0,

                accepted_item: dataAccept,

                return_quantity: resp.data.items_pod[index].adjusted_quantity
                  ? resp.data.items_pod[index].return_quantity
                  : resp.data.items_pod[index].quantity *
                    resp.data.items_pod[index].amount_uom,
                rejected_reason: responseRejectedReason,
              });

              //sorted itemLine by sku_name then sku_code
              this.itemLine.sort((a, b) => {
                if (a.item_name < b.item_name) {
                  return -1;
                }
                if (a.item_name > b.item_name) {
                  return 1;
                }
                return 0;
              });

              //check if status is failed_cancel then set rejected_reason quantity = quantity * amount_uom
              if (this.selectedStatus.ID == 4 || this.selectedStatus.ID == 5) {
                this.itemLine[index].adjusted_quantity = 0;
                let itemUnitLevel1 = this.itemLine[index].item_units.filter(
                  (unit) => unit.level == 1
                )[0];
                this.itemLine[index].rejected_reason[0].unit_name =
                  itemUnitLevel1.unit_name;

                this.itemLine[index].rejected_reason[0].unit_id =
                  itemUnitLevel1.unit_id;
                this.itemLine[index].rejected_reason[0].id = itemUnitLevel1.id;
                this.itemLine[index].rejected_reason[0].amount_uom =
                  itemUnitLevel1.amount_uom;
                this.itemLine[index].rejected_reason[0].level =
                  itemUnitLevel1.level;
                this.itemLine[index].rejected_reason[0].quantity =
                  this.itemLine[index].qty_after_conv;

                this.optionReason = this.optionReasonMain.filter(
                  (reason) => reason.reason_group == "POD Failed"
                );

                //get data selectedReason from itemLine rejected_reason index 0
                this.selectedReason = this.optionReasonMain.filter(
                  (reason) =>
                    reason.id == this.itemLine[0].rejected_reason[0].reason.id
                )[0];
              } else {
                this.optionReason = this.optionReasonMain.filter(
                  (reason) => reason.reason_group == "POD Partial"
                );
              }
            }

            // console.log("ini itemLine", this.itemLine);

            // console.log("weee", this.table.data);
            if (!this.isDelivered && this.table.data.notes == "ePOD") {
              this.handleCheckIfDelivered();
              this.$vs.notify({
                title: "Error",
                text: "This POD is not delivered yet",
                color: "warning",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
            }

            if (this.selectedStatus.ID != 2) {
              this.handleSelectStatus();
            }

            this.setStartEnd();
            // this.table.data.items_pod.forEach((element) => {
            //   if (element.reason_id != null) {
            //     this.selectedReason = element.reason_id;
            //     return;
            //   }
            // });
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    SubmitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Confirm`,
            text: "Are you sure to Save this data?",
            accept: () => {
              this.submit();
            },
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Please complete the form",
            color: "warning",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      });
    },

    submit() {
      if (this.selectedStatus.ID == 0 || this.selectedStatus.ID == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select status",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }
      if (
        this.selectedStatus.ID == 2 ||
        this.selectedStatus.ID == 4 ||
        this.selectedStatus.ID == 5
      ) {
        let isReason = false;
        for (let index = 0; index < this.itemLine.length; index++) {
          if (this.itemLine[index].rejected_reason.length > 0) {
            for (
              let indexReason = 0;
              indexReason < this.itemLine[index].rejected_reason.length;
              indexReason++
            ) {
              if (
                this.itemLine[index].rejected_reason[indexReason].reason != null
              ) {
                this.itemLine[index].reason_id =
                  this.itemLine[index].rejected_reason[indexReason].reason.id;
                isReason = true;
                break;
              }
            }
          }
          if (this.selectedStatus.ID == 2) {
            // console.log(">>>>>ini itemLine", this.itemLine);
            //check if all itemLine.accepted_item.quantity is 0 then return error
            let dataQty0 = this.itemLine.filter(
              (item) => item.adjusted_quantity == 0
            );

            //convert string to number in itemLine.rejected_reason[index].quantity
            this.itemLine.forEach((item) => {
              item.rejected_reason.forEach((reason) => {
                reason.quantity = Number(reason.quantity);
                reason.item_unit_id = reason.id;
              });
            });
            if (dataQty0.length == this.itemLine.length) {
              this.$vs.notify({
                title: "Error",
                text: "This POD should be set status to Failed Cancel",
                color: "warning",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
              return;
            }

            let dataQty = this.itemLine.filter(
              (item) => item.adjusted_quantity == item.qty_after_conv
            );

            if (dataQty.length == this.itemLine.length) {
              this.$vs.notify({
                title: "Error",
                text: "This POD should be set status to Complete",
                color: "warning",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
              return;
            }
          }
        }

        if (!isReason) {
          this.$vs.notify({
            title: "Error",
            text: "Please select reason",
            color: "warning",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return;
        }
      }

      let submitItem = {};
      submitItem.items_pod = [];

      submitItem.id = this.table.data.id;
      submitItem.delivery_order_id = this.table.data.delivery_order_id;
      submitItem.delivery_order_code = this.table.data.delivery_order_code;
      submitItem.transport_plan_id = this.table.data.transport_plan_id;
      submitItem.transport_plan_code = this.table.data.shipment_number;
      submitItem.proof_of_delivery_date =
        this.table.data.proof_of_delivery_date;
      submitItem.sales_order_code = this.table.data.sales_order_code;
      // submitItem.posting_date = this.dateNow;
      submitItem.status_delivery = this.selectedStatus.ID;
      submitItem.is_document = this.table.data.is_document;
      submitItem.reason_id = this.selectedReason ? this.selectedReason.id : 0;

      let epod = "";
      if (this.$route.query.epod != "") {
        epod = this.$route.query.epod;
      }

      submitItem.items_pod = this.itemLine;

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/proof-of-delivery/save", {
          proof_of_delivery: submitItem,
          e_pod: epod,
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Proof of Delivery has been saved",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            // this.IDitem.id = resp.data.proof_of_delivery_id;
            // this.getData();
            this.handleClose();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    async getReasons(params) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/reason", {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionReasonMain = resp.data.records;
              if (this.optionReasonMain.length > 0) {
              } else {
                this.optionReasonMain = [];
                this.selectedReason = { reason: "" };
              }
            } else {
              this.optionReasonMain = [];
              this.selectedReason = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    customLabelReason({ reason }) {
      return `${reason} `;
    },
    customLabelStatus({ name }) {
      return `${name} `;
    },
    handleClose() {
      // back to list
      this.$vs.loading();
      this.$router.push({ name: "proof-of-delivery" });
      this.$vs.loading.close();
    },
    handleSelectStatus() {
      if (this.selectedStatus.ID == 5) {
        this.disableRejectAndAccept = true;
        this.itemLine.forEach((item) => {
          item.adjusted_quantity = null;
          item.adjusted_quantity = 0;
          item.item_units.sort((a, b) => b.level - a.level);

          let itemUnitLevel = null;
          for (let index = 0; index < item.item_units.length; index++) {
            if (item.qty_after_conv % item.item_units[index].amount_uom == 0) {
              itemUnitLevel = item.item_units[index];
              item.return_quantity =
                item.qty_after_conv / item.item_units[index].amount_uom;
              item.adjusted_quantity = 0;
              break;
            }
          }

          item.accepted_item = itemUnitLevel;

          item.rejected_reason[0].unit_name = itemUnitLevel.unit_name;
          item.rejected_reason[0].unit_id = itemUnitLevel.unit_id;
          item.rejected_reason[0].id = itemUnitLevel.id;
          item.rejected_reason[0].amount_uom = itemUnitLevel.amount_uom;
          item.rejected_reason[0].level = itemUnitLevel.level;
          item.rejected_reason[0].quantity = item.quantity;
          item.return_quantity = item.quantity;
        });
        this.optionReason = this.optionReasonMain.filter(
          (reason) => reason.reason_group == "POD Failed"
        );
      } else if (this.selectedStatus.ID == 1) {
        this.selectedReason = 0;
        this.itemLine.forEach((tr) => {
          // tr.adjusted_quantity = tr.quantity;
          this.disabledReason = true;
          this.disableRejectAndAccept = true;
          tr.reason_id = null;
          tr.notes = null;

          tr.item_units.sort((a, b) => b.level - a.level);

          for (let index = 0; index < tr.item_units.length; index++) {
            if (tr.qty_after_conv % tr.item_units[index].amount_uom == 0) {
              tr.accepted_item = tr.item_units[index];
              tr.adjusted_quantity =
                tr.qty_after_conv / tr.item_units[index].amount_uom;
              tr.return_quantity = 0;
              break;
            }
          }

          //for rejected reason set to 0
          tr.rejected_reason = [
            {
              amount_uom: 0,
              level: 0,
              unit_name: "",
              unit_id: 0,
              id: 0,
              quantity: 0,
              is_new: false,
              reason: null,
            },
          ];
        });
      } else if (this.selectedStatus.ID == 2) {
        this.itemLine.forEach((tr) => {
          this.disabledReason = false;
          this.disableRejectAndAccept = false;
          tr.reason_id = null;
          tr.notes = null;

          tr.item_units.sort((a, b) => a.level - b.level);
          for (let index = 0; index < tr.item_units.length; index++) {
            if (tr.item_units[index].level == 1) {
              tr.accepted_item = tr.item_units[index];
              tr.adjusted_quantity = tr.qty_after_conv;
              tr.rejected_reason = [
                {
                  amount_uom: tr.item_units[index].amount_uom,
                  level: tr.item_units[index].level,
                  unit_name: tr.item_units[index].unit_name,
                  unit_id: tr.item_units[index].unit_id,
                  id: tr.item_units[index].id,
                  quantity: null,
                  is_new: false,
                  reason: null,
                },
              ];
              tr.return_quantity = 0;
              break;
            }
          }
        });

        this.optionReason = this.optionReasonMain.filter(
          (reason) => reason.reason_group == "POD Partial"
        );
      } else if (this.selectedStatus.ID == 4) {
        this.disableRejectAndAccept = true;
        this.itemLine.forEach((item) => {
          item.adjusted_quantity = null;
          item.adjusted_quantity = 0;
          item.item_units.sort((a, b) => b.level - a.level);

          let itemUnitLevel = null;
          for (let index = 0; index < item.item_units.length; index++) {
            if (item.qty_after_conv % item.item_units[index].amount_uom == 0) {
              itemUnitLevel = item.item_units[index];
              item.return_quantity =
                item.qty_after_conv / item.item_units[index].amount_uom;
              item.adjusted_quantity = 0;
              break;
            }
          }

          item.accepted_item = itemUnitLevel;

          item.rejected_reason[0].unit_name = itemUnitLevel.unit_name;
          item.rejected_reason[0].unit_id = itemUnitLevel.unit_id;
          item.rejected_reason[0].id = itemUnitLevel.id;
          item.rejected_reason[0].amount_uom = itemUnitLevel.amount_uom;
          item.rejected_reason[0].level = itemUnitLevel.level;
          item.rejected_reason[0].quantity = item.quantity;
          item.return_quantity = item.quantity;
        });
        this.optionReason = this.optionReasonMain.filter(
          (reason) => reason.reason_group == "POD Failed"
        );
      } else {
        this.disabledReason = false;
        this.disableRejectAndAccept = false;
      }
    },

    selectedUnit(indextr) {
      this.itemLine[indextr].adjusted_quantity = 0;
    },
    rejectedCheck(indextr, index) {
      // console.log(">>>>www", indextr, index);
      // console.log(">>>>ckckkc", this.itemLine[indextr]);
      // if (this.huSelectTransit.unit_id != 0) {
      //   const rejectedReason = this.itemLine[indextr].rejected_reason;
      //   const lastIndex = rejectedReason.length - 1;
      //   const huSelectTransit = this.huSelectTransit;

      //   console.log(">>>>TTTT", index, lastIndex);

      //   rejectedReason[lastIndex].unit_name = huSelectTransit.unit_name;
      //   rejectedReason[lastIndex].unit_id = huSelectTransit.unit_id;
      //   rejectedReason[lastIndex].id = huSelectTransit.id;
      //   rejectedReason[lastIndex].amount_uom = huSelectTransit.amount_uom;
      //   rejectedReason[lastIndex].level = huSelectTransit.level;
      // }

      //check if unit_name was selected
      if (this.itemLine[indextr].rejected_reason[index].unit_name != "") {
        let totalQty = 0;
        this.itemLine[indextr].rejected_reason.forEach((element) => {
          //convert to level 1
          let qty = element.quantity;
          if (element.level != 1) {
            qty = element.quantity * element.amount_uom;
          }
          totalQty += Number(qty);
        });

        this.itemLine[indextr].adjusted_quantity =
          this.itemLine[indextr].qty_after_conv - totalQty < 0
            ? this.itemLine[indextr].adj_qty_old
            : this.itemLine[indextr].qty_after_conv - totalQty;

        //convert adjusted quantity to level 1
        let adjustedQty = this.itemLine[indextr].adjusted_quantity;
        if (this.itemLine[indextr].accepted_item.level != 1) {
          adjustedQty =
            this.itemLine[indextr].adjusted_quantity *
            this.itemLine[indextr].accepted_item.amount_uom;
        }
        let allTotalQty = totalQty + adjustedQty;
        let remaningQty = allTotalQty - adjustedQty;
        this.itemLine[indextr].return_quantity = remaningQty;

        if (
          allTotalQty > this.itemLine[indextr].qty_after_conv ||
          totalQty < 0
        ) {
          // console.log("sssss", totalQty, adjustedQty);
          this.$vs.notify({
            title: "Error",
            text: "Total quantity input can't be greater than Total Qty or less than 0",
            color: "danger",
            position: "top-right",
          });
          this.itemLine[indextr].rejected_reason[index].quantity = 0;

          return;
        } else {
          this.itemLine[indextr].adj_qty_old =
            this.itemLine[indextr].adjusted_quantity;
        }
      }
    },

    handleSelectReason() {
      //set all reason_id on itemLine.rejected_reason to selectedReason
      this.itemLine.forEach((tr) => {
        tr.rejected_reason.forEach((reason) => {
          reason.reason = this.selectedReason;
        });
      });
    },
    handleOpenTr() {
      this.addTr += 30;
    },
    handleCloseTr() {
      this.addTr = 0;
    },

    handleCheckEditOrShow() {
      if (this.$route.query.action == "Show") {
        this.disableRejectAndAccept = true;
        this.disabledReason = true;
        this.editOrShow = true;
      }
    },
    handleCheckIfDelivered() {
      this.disableRejectAndAccept = true;
      this.disabledReason = true;
      this.editOrShow = true;
    },

    ProcessData() {
      if (this.selectedStatus.ID == 0 || this.selectedStatus.ID == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select status",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }
      if (
        this.selectedStatus.ID == 2 ||
        this.selectedStatus.ID == 4 ||
        this.selectedStatus.ID == 5
      ) {
        let isReason = false;
        for (let index = 0; index < this.itemLine.length; index++) {
          if (this.itemLine[index].rejected_reason.length > 0) {
            for (
              let indexReason = 0;
              indexReason < this.itemLine[index].rejected_reason.length;
              indexReason++
            ) {
              if (
                this.itemLine[index].rejected_reason[indexReason].reason != null
              ) {
                this.itemLine[index].reason_id =
                  this.itemLine[index].rejected_reason[indexReason].reason.id;
                isReason = true;
                break;
              }
            }
          }
          if (this.selectedStatus.ID == 2) {
            // console.log(">>>>>ini itemLine", this.itemLine);
            //check if all itemLine.accepted_item.quantity is 0 then return error
            let dataQty0 = this.itemLine.filter(
              (item) => item.adjusted_quantity == 0
            );

            //convert string to number in itemLine.rejected_reason[index].quantity
            this.itemLine.forEach((item) => {
              item.rejected_reason.forEach((reason) => {
                reason.quantity = Number(reason.quantity);
                reason.item_unit_id = reason.id;
              });
            });
            if (dataQty0.length == this.itemLine.length) {
              this.$vs.notify({
                title: "Error",
                text: "This POD should be set status to Failed Cancel",
                color: "warning",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
              return;
            }

            let dataQty = this.itemLine.filter(
              (item) => item.adjusted_quantity == item.qty_after_conv
            );

            if (dataQty.length == this.itemLine.length) {
              this.$vs.notify({
                title: "Error",
                text: "This POD should be set status to Complete",
                color: "warning",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
              return;
            }
          }
        }

        if (!isReason) {
          this.$vs.notify({
            title: "Error",
            text: "Please select reason",
            color: "warning",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return;
        }
      }

      let submitItem = {};
      submitItem.items_pod = [];

      submitItem.id = this.table.data.id;
      submitItem.delivery_order_id = this.table.data.delivery_order_id;
      submitItem.delivery_order_code = this.table.data.delivery_order_code;
      submitItem.transport_plan_id = this.table.data.transport_plan_id;
      submitItem.transport_plan_code = this.table.data.shipment_number;
      submitItem.delivery_date = this.table.data.proof_of_delivery_date;
      submitItem.sales_order_code = this.table.data.sales_order_code;
      submitItem.posting_date = this.table.data.proof_of_delivery_date;
      submitItem.proof_of_delivery_date =
        this.table.data.proof_of_delivery_date;
      submitItem.status_delivery = this.selectedStatus.ID;
      submitItem.is_document = this.table.data.is_document;
      submitItem.reason_id = this.selectedReason ? this.selectedReason.id : 0;

      // let epod = "";
      // if (this.$route.query.epod != "") {
      //   epod = this.$route.query.epod;
      // }

      submitItem.items_pod = this.itemLine;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to Process this data?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .post("/api/wms/v1/proof-of-delivery/save", {
              proof_of_delivery: submitItem,
              is_proccessed: true,
            })
            .then((resp) => {
              // console.log(resp);
              if (resp.code == 200) {
                this.$vs.notify({
                  title: "Success",
                  text: "Proof of Delivery has been saved",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
                // this.IDitem.id = resp.data.proof_of_delivery_id;
                // this.getData();
                this.handleClose();
              } else {
                this.$vs.notify({
                  title: "Error",
                  text: resp.message,
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              }
              this.$vs.loading.close();
            });
        },
      });
    },
    addRowReason(indextr) {
      // console.log(">>>>", indextr);
      // console.log(">>>>", this.itemLine[indextr].rejected_reason);

      this.itemLine[indextr].rejected_reason.push({
        unit_name: "",
        unit_id: 0,
        id: 0,
        quantity: null,
        is_new: true,
        reason: null,
      });

      // console.log(">>>>111", this.itemLine[indextr].rejected_reason);
    },
    removeRowReason(indextr, index) {
      this.itemLine[indextr].rejected_reason.splice(index, 1);
      //calculate again adjusted quantity after remove row
      let totalQty = 0;
      this.itemLine[indextr].rejected_reason.forEach((element) => {
        //convert to level 1
        let qty = element.quantity;
        if (element.level != 1) {
          qty = element.quantity * element.amount_uom;
        }
        totalQty += Number(qty);
      });

      this.itemLine[indextr].adjusted_quantity =
        this.itemLine[indextr].qty_after_conv - totalQty < 0
          ? this.itemLine[indextr].adj_qty_old
          : this.itemLine[indextr].qty_after_conv - totalQty;
    },
  },
  watch: {
    //check if status complete then set reason, note and accepted quantity disabled then set value accepted qty= quantity:
    "selectedStatus.ID": {
      handler(newVal) {
        if (newVal == 1) {
          this.disabledReason = true;
          this.disableRejectAndAccept = true;
        } else if (newVal == 2) {
          this.disabledReason = false;
          this.disableRejectAndAccept = false;
        } else if (newVal == 5 || newVal == 4) {
          this.disabledReason = true;
          this.disableRejectAndAccept = true;
        } else {
          this.disabledReason = false;
          this.disableRejectAndAccept = true;
        }
      },
    },
    //check set adjusted quantity each itemLine is value
  },
  computed: {
    // setPage: {
    //   get() {
    //     return 1;
    //   },
    //   set(val) {
    //     this.handleChangePage(val);
    //   },
    // },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>

<style>
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
